import BackButton from "../objects/BackButton";
import BoloApp from "../objects/BoloApp";
import Title from "../objects/Title";
import discordIcon from "../images/discord.svg";

function Pathless() {
  return <>

    <BackButton />

    <Title text={"Bolo's Pathless"} />
    <p>Pathless is a fast paced, arcade style, spell based PVP game built for the newer versions of Minecraft: Java Edition. There are currently 2 major versions of the game.</p>

    <div className="socials-bar">
      <a href="https://discord.gg/6VsfQdnRsB"><img src={discordIcon} alt="Join the Discord"></img></a>
    </div>
    

    <BoloApp info={{
      title: "Pathless Descendants",
      desc: `
      With 23 unique spells, and 10 custom maps, Pathless Descendants is the most polished experience. 
      It's been maintained and updated by me since 2022, and created almost entirely with datapacks. This is the best way to experience Pathless.
      `,
      link: ""
    }}>

        <a href="https://youtu.be/w927ZtK-Pz4">Watch the Trailer</a>
    </BoloApp>

    <BoloApp info={{
        title: "Pathless Origins",
        desc: `
        A 100% Paper Plugin version of the game with an elemental theme. 
        It's a bit more clunky, and has far less content, but it's still pretty damn fun. 
        This version was created by me and MrTron in late 2023, and is no longer maintained. 
        Though this version is publically available at mc.tronmc.com. 
        `,
        link: ""
        }} />

    

  </>;
}

export default Pathless;